import cards from "../data/cards.json";
import topCard from "../data/topCard.json";
import featured from "../data/featured.json";
import channels from "../data/liveChannels.json";
import movies from "../data/movies.json";

export const CategoryData = (name) => {
  const res = {
    topSec: topCard[name],
    featured,
    sections: [
      {
        // label: "Latest Movies",
        label: "Suggested For You",
        data: cards,
      },
      {
        // label: "Channels",
        label: "Top 5",
        data: [...channels,],
      },
      {
        label: "Movies",
        data: movies,
      },
    ],
  };

  return res;
};
