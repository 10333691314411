import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { Box, Typography, Grid } from "@mui/material";

export const Contact = () => {
  return (
    <>
      <Header />
      <Typography variant="h3" textAlign="center">
        <b>Contact Us</b>
      </Typography>
      <Box
        height="50vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container spacing={5}>
          <Grid item md={6}>
            <Typography textAlign="center">
              <b>Email</b>
            </Typography>
            <br />
            <Typography textAlign="center">
              eternitycreativestudioblr@gmail.com
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography textAlign="center">
              <b>Phone</b>
            </Typography>
            <br />
            <Typography textAlign="center">+91 - 9902649602</Typography>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};
