import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { useEffect, useState } from "react";
import style from "assets/style.module.css";
import { Navigation, Autoplay } from "swiper";
import { PlayArrow } from "@mui/icons-material";
import { UISkeleton } from "components/Skeleton";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { CategoryData } from "middleware/category";
import {
  Box,
  Grid,
  Button,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";

export const Category = () => {
  const { name } = useParams();

  const [data, setData] = useState({});


  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const res = CategoryData(name);
    setData(res);

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [name]);

  if (Object.keys(data).length === 0) {
    return (
      <>
        <Header />
        <UISkeleton />
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <section
        className={style.top_sec}
        style={{ backgroundImage: `url(${data.topSec?.image})` }}
      >
        <Container maxWidth="xl">
          <Grid container>
            <Grid item md={6}>
              <Box mt={isMatch ? 5 : 10}>
                <Typography variant="h2">
                  <b>{data.topSec?.name}</b>
                </Typography>
                <br />
                <Typography>
                  <b>Kannada, Entertainment</b>
                </Typography>
                <br />
                <Typography>
                The OTTplay Movies page offers more than 180,000 titles for users to explore through the app or website. The various language filters allow the users to select the language of their preference to pick the right film. The languages include, English, Hindi, Telugu, Malayalam, Tamil, Kannada, Bengali, Marathi, Gujarati, Punjabi, and Odia. The user will be able to further filter out titles based on ‘genre’ through options such as comedy, action, crime, mystery, thriller, adventure, horror, western, war, fantasy, musical, documentary, sci-fi, history, drama, romance, and animation.
                </Typography>
                <br />
                <br />
                <Link to={`/player/${data.topSec?.id}`}>
                  <Button variant="contained" color="error">
                    <PlayArrow />
                    &nbsp;&nbsp;&nbsp;
                    <b>Play Now</b>
                  </Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>
      <br />
      <br />
      <br />
      <Container maxWidth="xl">
        {/* <Typography variant="h5">Featured Shows</Typography> */}
        <Typography variant="h5">Short Clips</Typography>
        <br />
        <Grid container spacing={1}>
          {data.featured &&
            data.featured.map((e, index) => {
              return (
                <Grid key={index} item md={2} xs={4}>
                  <Link to={`/player/${e.id}`}>
                    <div className={style.item}>
                      <img loading="lazy" src={e.image} alt="Card" />
                    </div>
                  </Link>
                </Grid>
              );
            })}
        </Grid>
      </Container>
      <br />
      <br />
      <br />
      {data.sections &&
        data.sections.map((e, index) => {
          return (
            <Box key={index}>
              <Container maxWidth="xl">
                <Typography variant="h5">{e.label}</Typography>
                <br />
                <Swiper
                  modules={[Navigation, Autoplay]}
                  slidesPerView={isMatch ? 2 : 6}
                  navigation
                  autoplay
                >
                  {e.data.map((ele, inx) => {
                    return (
                      <SwiperSlide key={inx}>
                        <Link to={`/player/${ele.id}`}>
                          <Box sx={{ p: 1 }}>
                            <img loading="lazy" src={ele.image} alt="Card" />
                          </Box>
                        </Link>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Container>
              <br />
              <br />
              <br />
            </Box>
          );
        })}
      <Footer />
    </>
  );
};
