import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Container, Typography, Grid } from "@mui/material";

export const About = () => {
  return (
    <>
      <Header />
      <Container maxWidth="lg">
        <br />
        <br />
        <Typography variant="h3" textAlign="center">
          <b>About Us</b>
        </Typography>
        <br />
        <br />
        <Typography>
          <b>Eternity Creative Studio in Jakkur Layout, Bangalore</b>
        </Typography>
        <br />
        <Typography>
          Eternity Creative Studio in Bangalore is one of the leading businesses
          in the Corporate Ad Film Makers. Also known for Film Studios, Film
          Makers, Film Shooting Location, Ad Film Makers, Documentary Film
          Makers, Corporate Film Makers, Music Album Makers, TV Ad Film Makers
          and much more. Find Address, Contact Number, Reviews & Ratings,
          Photos, Maps of Eternity Creative Studio, Bangalore.
        </Typography>
        <br />
        <Typography>
          <b>Location and Overview :</b>
        </Typography>
        <br />
        <Typography>
          Established in the year 2016, Eternity Creative Studio in Jakkur
          Layout, Bangalore is a top player in the category Corporate Ad Film
          Makers in the Bangalore. This well-known establishment acts as a
          one-stop destination servicing customers both local and from other
          parts of Bangalore. Over the course of its journey, this business has
          established a firm foothold in it’s industry. The belief that customer
          satisfaction is as important as their products and services, have
          helped this establishment garner a vast base of customers, which
          continues to grow by the day. This business employs individuals that
          are dedicated towards their respective roles and put in a lot of
          effort to achieve the common vision and larger goals of the company.
          In the near future, this business aims to expand its line of products
          and services and cater to a larger client base. In Bangalore, this
          establishment occupies a prominent location in Jakkur Layout. It is an
          effortless task in commuting to this establishment as there are
          various modes of transport readily available. It is at Marinanjappa
          Street, Jakkur, Yelahanka Hobli, Behind Jakkur Aerodrome, which makes
          it easy for first-time visitors in locating this establishment. It is
          known to provide top service in the following categories: Film
          Studios, Film Makers, Film Shooting Location, Ad Film Makers,
          Documentary Film Makers, Corporate Film Makers, Music Album Makers, TV
          Ad Film Makers.
        </Typography>
        <br />
        <Typography>
          <b>Products and Services offered :</b>
        </Typography>
        <br />
        <Typography>
          Eternity Creative Studio in Jakkur Layout has a wide range of products
          and / or services to cater to the varied requirements of their
          customers. The staff at this establishment are courteous and prompt at
          providing any assistance. They readily answer any queries or questions
          that you may have. Pay for the product or service with ease by using
          any of the available modes of payment, such as Cheques. This
          establishment is functional from 06:00 - 22:00.
        </Typography>
        <br />
        <Typography>
          Please scroll to the top for the address and contact details of
          Eternity Creative Studio at Jakkur Layout, Bangalore.
        </Typography>
      </Container>
      <Footer />
    </>
  );
};
