import { Navigation } from "swiper";
import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { useEffect, useState } from "react";
import { ReadMore } from "service/ReadMore";
import { PlayerData } from "middleware/player";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Box,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";

export const Player = () => {
  const { videoId } = useParams();

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));

  const [data, setData] = useState({});

  useEffect(() => {
    const res = PlayerData(videoId);
    setData(res);

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [videoId]);

  return (
    <>
      <Header />
      <Container maxWidth="xl">

      {/* <video>
      <source src="https://drive.google.com/file/d/1-KbgD2_HMUvDWTCLetWZFjG9DpzwyQCW/preview" type='video/mp4'></source>
      </video> */}
     {/* <iframe src={`https://drive.google.com/file/d/${data.player?.embedCode}/preview`} allow="autoplay"></iframe> */}

     {/* <video width="750" height="500" controls>
      <source src={data.player?.embedCode} />
    </video> */}

    {/* firebase link */}
    <video src={data.player?.embedCode} style={{width:'100%'}} height="600" controls />


        {/* <iframe
          loading="lazy"
          src={`https://www.youtube.com/embed/${data.player?.embedCode}`}
        ></iframe> */}
      </Container>
      <Container maxWidth="xl" sx={{ mt: 5, mb: 5 }}>
        <Typography variant="h3">
          <b>{data.player?.name}</b>
        </Typography>
        <br />
        <br />
        <Typography>
          <b>Genre :</b> &nbsp;  Kannada, Entertainment
        </Typography>
        <br />
        <ReadMore>
        The OTTplay Movies page offers more than 180,000 titles for users to explore through the app or website. The various language filters allow the users to select the language of their preference to pick the right film. The languages include, English, Hindi, Telugu, Malayalam, Tamil, Kannada, Bengali, Marathi, Gujarati, Punjabi, and Odia. The user will be able to further filter out titles based on ‘genre’ through options such as comedy, action, crime, mystery, thriller, adventure, horror, western, war, fantasy, musical, documentary, sci-fi, history, drama, romance, and animation.
        </ReadMore>
      </Container>
      <Container maxWidth="xl" sx={{ mt: 10, mb: 10 }}>
        <Typography variant="h5">Recommended Shows</Typography>
        <br />
        <Swiper
          modules={[Navigation]}
          slidesPerView={isMatch ? 2 : 6}
          navigation
        >
          {data.recommended &&
            data.recommended.map((e, index) => {
              return (
                <SwiperSlide key={index}>
                  <Link to={`/player/${e.id}`}>
                    <Box sx={{ p: 1 }}>
                      <img loading="lazy" src={e.image} alt="Card" />
                    </Box>
                  </Link>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </Container>
      <Footer />
    </>
  );
};
